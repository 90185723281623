import '../App.css';
import '../css/Galeria.css';
import '../css/Responsive.css';
import './HotelSharey.jsx';





function Inicio() {
    return (
      <>
      

      <div className='contenido' style={{background:'#E6EBEB'}}>
        
    
       
       <div className='Seccion1'>
        <h1>Nuestros servicios</h1>

<div className='contenedor-galeria'>
        <div className='galeria'>

<div className='foto'>

  <img src="./img/r_pescadof.png" alt="" />

  </div>
  <div className='pie'>
  <h5>Restaurante</h5>

  <p>En nuestro restaurante nosotros ofrecemos todas las comidas tipicas en timbiqui! no olvides contactarnos</p>
    </div>
      </div>

      <div className='galeria'>

<div className='foto'>

  <img src="./img/galeria_evento1.png" alt="" />

  </div>
  <div className='pie'>
  <h5>Eventos</h5>

  <p>Realizamos diferentes eventos que nuestros clientes deseen en cualquiera de nuestros hoteles</p>
    </div>
      </div>

      <div className='galeria'>

<div className='foto'>

  <img src="./img/turismo3.png" alt="" />

  </div>
  <div className='pie'>
  <h5>Salidas turisticas</h5>

  <p>Realizamos salidas turisticas para que todos nuestros clientes conozcan los sitios mas lindos de timbiqui</p>

    </div>
      </div>

      </div>


       </div>

       </div>
      </>
    );
  }
  
  export default Inicio;

