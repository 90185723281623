import React, {useState} from 'react'
import styled from 'styled-components'
import BurguerButton from './BurguerButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBuilding, faHouse } from '@fortawesome/free-solid-svg-icons'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import 'react-bootstrap'
import BootstrapCarousel from './BootstrapCarousel'
import Inicio from './Inicio'
import Hotel1 from './HotelSharey'
import BootstrapCarousel2 from './BootstrapCarouselSharey'
import Menu from './Menu'
import Turismo from './Turismo'
import BootstrapCarouselturismo from './BootstrapCarouselturismo'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import '../App.css';
import HotelFaro from './HotelFaro'
import BootstrapCarouselFaro from './BootstrapCarouselFaro'
import HotelTimbiqui from './HotelTimbiqui'
import Nosotros from './Nosotros'
import BootstrapCarouselTimbiqui from './BootstrapCarouselTimbiqui'
import HotelHanna from './HotelHanna'
import BootstrapCarouselHanna from './BootstrapCarouselHanna'
// import Footer from './Footer'

import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons' 
import { faMapLocation } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'







function Navbar() {

    const [clicked, setClicked] = useState(false) 
    const handleClick = () => {

        setClicked(!clicked)

    }

    const [inicio, setinicio] = useState (true)
    const[hotel1, sethotel1] = useState (false)
    const [carousel1, setcarousel1] = useState(true)
    const [carousel2, setcarousel2] = useState (false)
    const [menu, setmenu] = useState (false)
    const [turismo, setturismo] = useState (false)
    const [carruturis,setcarruturis] = useState(false)
    const [hotelfaro, sethotelfaro] = useState (false)
    const [carousel_F, setcarouselF] = useState (false)
    const [hoteltimbiqui, sethoteltimbiqui] = useState (false)
    const [carousel_timbiqui, setcarousel_timbiqui] = useState (false)
    const [hotelhanna, sethotelhanna] = useState (false)
    const [carousel_hanna, setcarousel_hanna] = useState (false)
    const [nosotros, setnosotros] = useState (false)

    // dropdown

    const [dropdown, setDropdown]=useState(false)
    const abrirCerrardrop=()=>{

        setDropdown(!dropdown);
    }

    // fin del dropdown

    const r_inicio = function (e) { setinicio (true); sethotel1 (false); setcarousel1(true); setcarousel2 (false); setmenu (false);setturismo (false); setcarruturis (false);sethotelfaro(false); setcarouselF(false);sethoteltimbiqui(false); setcarousel_timbiqui (false);sethotelhanna(false); setcarousel_hanna(false);setnosotros(false);} 
    const a_hotel =  function (e) {setinicio (false); sethotel1(true); setcarousel1(false); setcarousel2(true);setmenu (false);setturismo (false); setcarruturis (false); sethotelfaro(false); setcarouselF(false);sethoteltimbiqui(false); setcarousel_timbiqui (false);sethotelhanna(false); setcarousel_hanna(false);setnosotros(false);}
    const m_menu = function (e)  {setinicio (false);setmenu (true);sethotel1(false); setcarousel2 (false); setcarousel1(false);setturismo (false); setcarruturis (false); sethotelfaro(false); setcarouselF(false);sethoteltimbiqui(false); setcarousel_timbiqui (false);sethotelhanna(false); setcarousel_hanna(false);setnosotros(false);}
    const t_turismo = function (e) {setinicio (false);setmenu (false);sethotel1(false); setcarousel2 (false); setcarousel1(false); setturismo (true); setcarruturis (true); sethotelfaro(false); setcarouselF(false);sethoteltimbiqui(false); setcarousel_timbiqui (false);sethotelhanna(false); setcarousel_hanna(false);setnosotros(false);}
    const hotel_faro = function (e) {setinicio (false);setmenu (false);sethotel1(false); setcarousel2 (false); setcarousel1(false); setturismo (false); setcarruturis (false); sethotelfaro(true); setcarouselF(true); sethoteltimbiqui(false); setcarousel_timbiqui (false);sethotelhanna(false); setcarousel_hanna(false);setnosotros(false);}
    const hotel_timbiqui = function (e) {setinicio (false);setmenu (false);sethotel1(false); setcarousel2 (false); setcarousel1(false); setturismo (false); setcarruturis (false); sethotelfaro(false); setcarouselF(false); sethoteltimbiqui(true); setcarousel_timbiqui (true);sethotelhanna(false); setcarousel_hanna(false);setnosotros(false);}
    const hotel_hanna = function (e) {setinicio (false);setmenu (false);sethotel1(false); setcarousel2 (false); setcarousel1(false); setturismo (false); setcarruturis (false); sethotelfaro(false); setcarouselF(false); sethoteltimbiqui(false); setcarousel_timbiqui (false); sethotelhanna(true); setcarousel_hanna(true);setnosotros(false);}
    const p_nosotros = function (e) {setinicio (false);setmenu (false);sethotel1(false); setcarousel2 (false); setcarousel1(false); setturismo (false); setcarruturis (false); sethotelfaro(false); setcarouselF(false); sethoteltimbiqui(false); setcarousel_timbiqui (false); sethotelhanna(false); setcarousel_hanna(false); setnosotros(true);}



    

  
        return (
        <>
           <Navcontainer>
            <div className='logo'>
           <h2>Hoteles <span>Timbiqui</span></h2>
           </div>
        <div className={`links ${clicked ? 'active' : ''}`}>
          <a onClick={r_inicio}href="#!"><FontAwesomeIcon icon={ faHouse } style={{padding:' 0px 8px 0px 0px'}}/>Inicio</a>

          <Dropdown isOpen={dropdown} toggle={abrirCerrardrop}>
                <DropdownToggle caret className='botondrop'>
                <FontAwesomeIcon icon={ faBuilding } style={{padding:' 0px 8px 0px 0px'}}/> Hoteles
                </DropdownToggle> 
                <DropdownMenu>
                    <DropdownItem onClick={a_hotel}>Hotel Sharey</DropdownItem>
                    <DropdownItem onClick={hotel_faro}>Hotel Faro</DropdownItem>
                    <DropdownItem onClick={hotel_timbiqui}>Hotel Timbiqui</DropdownItem>
                    <DropdownItem onClick={hotel_hanna}>Hotel Hanna</DropdownItem>
                </DropdownMenu>
            </Dropdown>
          
          <a onClick={m_menu}href="#!"><FontAwesomeIcon icon={ faUtensils } style={{padding:' 0px 8px 0px 0px'}}/>Menu</a>
          <a onClick={t_turismo}href="#!"><FontAwesomeIcon icon={ faCalendarCheck } style={{padding:' 0px 8px 0px 0px'}}/>Turismo</a>
          <a onClick={p_nosotros} href="#!"><FontAwesomeIcon icon={ faUser  } style={{padding:' 0px 8px 0px 0px'}}/>Nosotros</a>
         
          
           
        </div>

        
        <div className='burguer'>
          <BurguerButton clicked={clicked} handleClick={handleClick} />
        </div>
        <BgDiv className={`initial ${clicked ? ' active' : ''}`}></BgDiv>

       
            </Navcontainer> 


            {carousel1 && <BootstrapCarousel/>}
            { carousel2 && <BootstrapCarousel2/>}

         {inicio && <Inicio/>}
        { hotel1 && <Hotel1/>}

        {menu && <Menu/>}

        {carruturis && <BootstrapCarouselturismo/>}
        { turismo && <Turismo/>}

        { carousel_F && <BootstrapCarouselFaro/>}
       {hotelfaro && <HotelFaro/>} 

       {carousel_timbiqui && <BootstrapCarouselTimbiqui/>} 
       {hoteltimbiqui && <HotelTimbiqui/>}

        { carousel_hanna && <BootstrapCarouselHanna/>}
       { hotelhanna && <HotelHanna/>}
       { nosotros && <Nosotros/>}

       

       {/* whatssap */}


       <div className='whatssap'>
        <span style={{fontSize:'1rem', paddingRight:'10px'}}>Contactanos por Whatsapp!</span>
      <a href="https://api.whatsapp.com/send?phone=573104655049&text=%F0%9F%98%80%20Buen%20d%C3%ADa%2C%20estoy%20interesado%20en%20m%C3%A1s%20imformaci%C3%B3n"><FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon></a>  
        </div>

       {/* <Footer/> */}
        
       <div className='text-white py-3 bg-dark'>

<div className="container">

    <div className="row">

        {/* columna 1*/}

        <div className="col-md12 col-sm-4">
            <h4>Nosotros</h4>

            <ul className='list-unstyled'>
                <li> <a className='restaurante' onClick={p_nosotros}href="#!">  <FontAwesomeIcon icon={faPeopleGroup } style={{padding:' 0px 5px 0px 0px'}}/>Contacto</a> </li>
              
            </ul>
        </div>
        {/* columna 2*/}
        <div className="col-md12 col-sm-4 footer_m">
            <h4>servicios</h4>


             <ul className='list-unstyled'>
                <li><a className='restaurante' onClick={m_menu}href="#!"> <FontAwesomeIcon icon={faUtensils} style={{padding:' 0px 8px 0px 0px'}}/> Restaurante</a></li>
                <li> <a className='restaurante' onClick={t_turismo}href="#!"> <FontAwesomeIcon icon={faMapLocation} style={{padding:' 5px 5px 0px 0px'}}/> Salidas turisticas </a> </li>
        
            </ul> 
        </div>

        {/* columna 3*/}

        <div className="col-md12 col-sm-4">
            <h4>Redes sociales</h4>

            <ul className='list-unstyled'>
                <li> <FontAwesomeIcon icon={ faFacebook} style={{padding:' 0px 8px 0px 0px'}}/>Facebook</li>
                <li> <FontAwesomeIcon icon={ faInstagram} style={{padding:' 0px 8px 0px 0px'}}/>Instagram</li>
                <li><FontAwesomeIcon icon={ faTwitter } style={{padding:' 0px 6px 0px 0px'}}/>Twitter</li>
            </ul>
        </div>

    </div>
</div>
</div>


       
        </>
    )
}

export default Navbar

const Navcontainer = styled.nav`

h2{
    z-index: 11;
    font-weight: 400;
    color: white;
}

padding: .4rem;
background-color: #333;
display: flex;
align-items: center;
justify-content: space-between;

a {

    color: white;
    text-decoration: none;
    margin-right: 1rem;

}

a:hover{
    background-color: #5c636a;
}


.burguer{

    @media(min-width: 768px){

        display: none;
        z-index: 11;
    }
}

.links{

    position: absolute;
    top: -700px;
    left: -2000px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all .5s ease;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    a{

        color:white;
        font-size: 2rem;
        display: block;
    }
    @media(min-width: 768px){

        position: initial;
        margin: 0;
        a{
            font-size: 1rem;
            color: white;
            display: inline;
        }
    }

    
}

.links.active{
    z-index: 10;
    width: 100%;
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    left: 0;
    right: 0;
    /* text-align: center; */
    display: inline-flex;
    flex-direction: column;
    align-items: center;




    a{
        font-size: 2rem;
        margin-top: 1rem;
        color: white;
    }

    .botondrop{

            font-size: 2rem;
            padding-top: 15px;
    
    }


}


.burguer{
    @media(min-width: 768px){
      display: none;

    }
  }
`

const BgDiv = styled.div`
  background-color: #222;
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all .6s ease ;
  
  &.active{
    z-index: 9;
    border-radius: 0 0 80% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.burguer{
    z-index: 11;
}
















`
