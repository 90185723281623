import '../App.css';
import '../css/Galeria.css';
import '../css/Responsive.css';


function Menu() {
    return (
      <>

      <div className='contenido' style={{background:'#E6EBEB'}}>

        <div className='Seccion1'>
     <h1 style={{color:'black'}}>Nuestro restaurante!</h1>

     <p style={{fontWeight:'bold',padding:'10px 0px 0px 0px'}}>En hoteles timbiqui tenemos los mejores platos para ti y tu familia a un precio accesible! Para mas informacion puedes escribirnos a nuestro numero de whatssap!</p>

      <div className='contenedor-galeria'>
     <div className='galeria'>

      <div className='foto'>

        <img src="./img/r_camarones.png" alt="no hay imagen" />

      </div>
      <div className='pie'>
        <h5>Camarones</h5>
        <p>Precio: $  18.000</p>
      </div>
     </div>

     <div className='galeria'>

      <div className='foto'>

        <img src="./img/r_cangrejo.png" alt="" />

      </div>
      <div className='pie'>
      <h5>Cangrejo</h5>
        <p>Precio: $ 20.000</p>
      </div>
     </div>
            
     

     <div className='galeria'>

      <div className='foto'>

        <img src="./img/r_langosta.png" alt="" />

      </div>
      <div className='pie'>
        <h5>Langosta</h5>
        <p> Precio: $ 50.000</p>
      </div>
     </div>

     
     </div>

     </div>


     <div className='seccion2'>

      <div className='contenedor-galeria'>
     <div className='galeria'>

      <div className='foto'>

        <img src="./img/r_pescadof.png" alt="no hay imagen" />

      </div>
      <div className='pie'>
        <h5>Mojarra frita</h5>
        <p>Precio: $ 15.000</p>
      </div>
     </div>

     <div className='galeria'>

      <div className='foto'>

        <img src="./img/r_pescadof2.png" alt="" />

      </div>
      <div className='pie'>
      <h5>Pescado frito</h5>
        <p>Precio: $ 15.000</p>
      </div>
     </div>
            
     

     <div className='galeria'>

      <div className='foto'>

        <img src="./img/r_plato1.png" alt="" />

      </div>
      <div className='pie'>
      <h5>Almuerzo casero</h5>
        <p> precio: 15.000</p>
      </div>
     </div>

     
     </div>

     </div>

     

       </div>


       
      </>
    );
  }
  
  export default Menu;
