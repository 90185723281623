
import React from 'react'
import { Carousel } from 'react-bootstrap'

export default function BootstrapCarousel() {
    return (
        <div style={{background:'##E6EBEB'}}>
          <h1>Nuestros Hoteles</h1>
            <Carousel >
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: '600px'}}
      src="./img/hotel_sharey.png"
      alt="First slide"
    />
    <Carousel.Caption>
      <h3>Hote Sharey</h3>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/hotel_faro.png"
      alt="Second slide"
    />

    <Carousel.Caption>
      <h3>Hotel Faro</h3>
 
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/hotel_timbiqui.png"
      alt="Third slide"
    />

<Carousel.Caption>
      <h3>Hotel Timbiqui</h3>
 
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/hotel_hanna.png"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>Hotel Hanna</h3>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
        </div>
    )
}

