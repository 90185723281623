import './App.css';
import Navbar from './components/Navbar';
import React, { Component } from 'react';



class  App extends Component {
 

  render(){

    return (

      <div className='app_principal'>


  
        <Navbar/>
        
       
     
      </div>
    );

  }

 
}

export default App;
