import '../App.css';
import '../css/Galeria.css';
import '../css/Responsive.css';






function HotelHanna() {
    return (
      <>

      <div className='contenido' style={{background:'#E6EBEB'}}>

        <div className='Seccion1'>
     <h1>Habitaciones</h1>

      <div className='contenedor-galeria'>
     <div className='galeria'>

      <div className='foto'>

        <img src="./img/galeriahanna.png" alt="no hay imagen" />

      </div>
      <div className='pie'>
        <h5>Habitacion personal</h5>
      </div>
     </div>

   
  
     </div>

     </div>
       
       <div className='seccion2'>
        <h1>Nuestras instalaciones</h1>

<div className='contenedor-galeria'>
        <div className='galeria'>

<div className='foto'>

  <img src="./img/galeriahanna3.png" alt="" />

  </div>
  <div className='pie'>
  <p>Estructura</p>
    </div>
      </div>

      <div className='galeria'>

<div className='foto'>

  <img src="./img/galeriahanna5.png" alt="" />

  </div>
  <div className='pie'>
  <p>Nuestros pasillos</p>
    </div>
      </div>



      </div>


       </div>

       </div>
      </>
    );
  }
  
  export default HotelHanna;
