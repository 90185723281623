import '../App.css';
import '../css/Galeria.css';
import '../css/Responsive.css';


function Turismo() {
    return (
      <div>



      <div className='contenido' style={{padding:'20px 0px 0px 0px',background:'#E6EBEB'}}>

        
<h1>Lugares y fauna!</h1>

        <div className='Seccion1'>
    

      <div className='contenedor-galeria'>
     <div className='galeria'>

      <div className='foto'>

        <img src="./img/turismo1.png" alt="no hay imagen" />

      </div>
      <div className='pie'>
        <h5>Ranas</h5>
        <p>las ranas son unas especies muy comun en timbiqui, estas son venenosas, por lo tanto evite contacto con ellas</p>
      </div>
     </div>

     <div className='galeria'>

      <div className='foto'>

        <img src="./img/turismo2.png" alt="" />

      </div>
      <div className='pie'>
      <h5>Colibri</h5>
        <p>Nuestros colibris son las aves mas protegidas de todo timbiqui</p>
      </div>
     </div>
            
     

     <div className='galeria'>

      <div className='foto'>

        <img src="./img/turismo3.png" alt="" />

      </div>
      <div className='pie'>
        <h5>Rios</h5>
        <p>hay variedades de lindos y hermosos rios en todo timbiqui para disfrutar y refrescarnos</p>
      </div>
     </div>


     

     
     </div>

     </div>


     <div className='seccion2'>

      <h1>Caminatas en senderos ecologicos</h1>
    

    <div className='contenedor-galeria'>
   <div className='galeria'>

    <div className='foto'>

      <img src="./img/sendero1.png" alt="no hay imagen" />

    </div>
    <div className='pie'>
    <h5>Conoce nuestras selvas tropicales!</h5>
   
    
    </div>
   </div>

   <div className='galeria'>

    <div className='foto'>

      <img src="./img/sendero2.png" alt="" />

    </div>
    <div className='pie'>
    <h5>Caminatas por nuestros bosques</h5>
     
    </div>
   </div>
          
   

   <div className='galeria'>

    <div className='foto'>

      <img src="./img/sendero3.png" alt="" />

    </div>
    <div className='pie'>
      <h5>Conoce nuestras especies de plantas!</h5>
      
    </div>
   </div>


   

   
   </div>

   </div>

     

       </div>


       
      </div>
    );
  }
  
  export default Turismo;
