import '../App.css';
import '../css/Galeria.css';
import '../css/Responsive.css';


function Turismo() {
    return (
      <div>



      <div className='contenido' style={{padding:'20px 0px 0px 0px',background:'#E6EBEB'}}>

        
<h1>Nosotros</h1>

        <div className='Seccion1'>
    

      <div className='contenedor-galeria'>
 

     <div className='galeria'>

      <div className='foto'>

        <img src="./img/gerardo.png" alt="" />

      </div>
      <div className='pie'>
      <h5>Gerardo Florez Viafara</h5>
        <p>Hola! mi nombre es gerardo, soy el fundador de la empresa hoteles timbiqui puedes contactarme en el boton de whatssap que hay en la pagina 
          y con mucho gusto te atendere y te brindare toda la asesoria que necesites para tu comodidad y para que conozcas los lugares mas lindos de timbiqui!
        </p>
      </div>
     </div>
            
     


     
     </div>

     </div>

     

       </div>


       
      </div>
    );
  }
  
  export default Turismo;
