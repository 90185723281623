import React from 'react'
import { Carousel } from 'react-bootstrap'

export default function BootstrapCarouselSharey() {
    return (
        <div style={{background:'#E6EBEB'}}>

<h1>Hotel Sharey</h1>
          
            <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/hotel_sharey.png"
      alt="First slide"
    />
    <Carousel.Caption>
      <h3 style={{color:'#E9E8E8', textShadow: '1px 2px 2px black, 0 0 5px black'}}>Bienvenidos al Hotel sharey!</h3>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/Carrousel_s2.png"
      alt="Second slide"
    />

    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/Carrousel_s1.png"
      alt="Third slide"
    />

    <Carousel.Caption>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
        </div>
    )
}

